@media(min-width:768px){
  .colores{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
}
.button-randomize{
    position: absolute;
    display: flex;
}
.sliders{
    position: absolute;
    top: 50%;
    left:50%;
    display: flex;
    transform: translate(-50%, -50%);
}

input.sliderA {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px; 
    background: linear-gradient(to right, black 0%, #FF0000 50%, #FF0000 100%);
    outline: none; 
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out; 
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);

  }
  input.sliderB {
    -webkit-appearance: none; 
    appearance: none;
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, #000000 0%, #00FF00 50%, #00FF00 100%);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);

  }
  input.sliderC {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, #000000 0%, #0000FF 50%, #0000FF 100%);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);

  }
  
 
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: background 0.2s ease-in-out;
  }
  
 
  input[type='range']:hover {
    opacity: 1;
  }
  
 
  input.sliderA:hover::-webkit-slider-thumb {
    background: #FF4000;
  }
  input.sliderB:hover::-webkit-slider-thumb {
    background: #40FF00;
  }
  input.sliderC:hover::-webkit-slider-thumb {
    background: #0040FF;
  }
  
 
  input[type='range']::-moz-range-thumb {
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  
  input[type='range']::-ms-thumb {
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
.slidecontainer{
    margin:1vh;
    
}
#red-text, #blue-text, #green-text{
    margin:2vh;
    padding: 3vh;
    border-radius: 5px;
    color:white;
    box-shadow: 0px 5px 5px -1px rgba(0, 0, 0, 0.5);
    font-family: monospace;
    font-size: xx-large;
    text-align: center;
    user-select: none;
}
.button-randomize{
    user-select: none;
    top:50%;
    left:50%;
    transform: translate(-50%, -350%);
    font-family: monospace;
    font-weight: 0;
    font-size: xx-large;
    color: #fff;
    background-color: #0066CC;
    padding: 10px 30px;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
    border: none;
    border-color: none;
    border-radius: 50px;
    transition : 1000ms;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.button-randomize:active{
    animation: ChangeSize 0.3s ease-in-out;
}
.text-color{
    display: flex;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, 150%);
    font-family: monospace;
    font-size: xx-large;
    font-weight: bold;
}
@keyframes ChangeSize {
    0%{
        scale: 1;
        box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
    }50%{
        scale: 0.99;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
    }100%{
        scale: 1;
        box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
    }
}
}
@media (max-width: 768px) {
  .colores {
      position: absolute; 
      width: 100%;
      height: 100%;
  }
  .sliders{
    position: inherit;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
}
input.sliderA {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px; 
  background: linear-gradient(to right, black 0%, #FF0000 50%, #FF0000 100%);
  outline: none; 
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out; 
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
  transition: 1000ms;
}
input.sliderB {
  -webkit-appearance: none; 
  appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #000000 0%, #00FF00 50%, #00FF00 100%);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
  transition: 1000ms;
}
input.sliderC {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #000000 0%, #0000FF 50%, #0000FF 100%);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
}
.text-color{
  display: flex;
  position: absolute;
  top:65%;
  left:40%;
  transform: translate(-40%, 50%);
  font-family: monospace;
  font-size: xx-large;
  font-weight: bold;
  margin: 2vh;
  transition: 1000ms;
  text-align: center;
}
#red-text, #blue-text, #green-text{
  position: inherit;
  margin:3vh;
  border-radius: 5px;
  color:white;
  box-shadow: 0px 5px 5px -1px rgba(0, 0, 0, 0.5);
  font-family: monospace;
  font-size: x-large;
  text-align: center;
  user-select: none;
  padding: 1.5vh;
}
.button-randomize{
  position: absolute;
  user-select: none;
  top: 15%;
  left:50%;
  transform: translate(-50%,50%);
  font-family: monospace;
  font-weight: 0;
  color:#fff;
  padding:10px 30px;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
  border: none;
  border-color: none;
  border-radius: 15px;
  transition: 1000ms;
}
.button-randomize:active{
  animation:ChangeSize 0.3s ease-in-out;
}
@keyframes ChangeSize {
  0%{
      scale: 1;
      box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
  }50%{
      scale: 0.99;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
  }100%{
      scale: 1;
      box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.5);
  }
}
}