
footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    text-align: center;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container{
    display: flex;
    align-items: center;
}
.social-icon{
    margin: 0 2vh;
    font-size: 24px;
}
a:visited{
    color:black;
}
a:hover{
    animation: sizeup 2s ease-in-out;
}
@keyframes sizeup {
    from{
        scale: 1;
    }
    to{
        scale: 1.05;
    }
}
@media (max-width: 480px) {
    a:visited{
        color:black !important;
    }
    .custom-color:visited{
        color:black !important;
    }
}